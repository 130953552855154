


















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { createLayout } from '~/common/utils/static-content/layout'
import type { ContentPage } from '~/modules/contentstack/types'

import ImageMap from '~/components/feature/static-content/ImageMap.vue'
import Tiles from '~/components/feature/static-content/Tiles.vue'
import Hero from '~/components/feature/static-content/Hero.vue'
import Menu from '@/components/feature/static-content/content-page/menu/index.vue'
import Reference from '@/components/feature/static-content/content-page/reference/index.vue'
import Carousel from '@/components/feature/static-content/content-page/carousel/index.vue'
import RichText from '@/components/feature/static-content/RichText.vue'
import MediaRoomAccordions from '~/components/feature/static-content/MediaRoomAccordions.vue'
import Accordion from '@/components/feature/static-content/AccordionWrapper.vue'
import CustomComponent from '~/components/feature/static-content/content-page/customComponent/index.vue'

import { CONTENT_TEMPLATE_COLUMN_DEVKEYS } from '~/common/constants/staticContent'

export default defineComponent({
  components: {
    ImageMap,
    Menu,
    Carousel,
    Tiles,
    Reference,
    RichText,
    MediaRoomAccordions,
    Accordion,
    Hero,
    CustomComponent,
  },
  props: {
    data: {
      required: true,
      type: Object as PropType<ContentPage>,
    },
    mediaRoomContent: {
      required: false,
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const templateLayout = createLayout(props.data)

    const contentTemplateDevkey = computed(() => {
      return props.data?.templateConnection?.edges?.at(0)?.node?.devkey ?? ''
    })

    const displayColumnTemplate = computed(() => {
      return CONTENT_TEMPLATE_COLUMN_DEVKEYS.includes(contentTemplateDevkey.value)
    })
    return { templateLayout, displayColumnTemplate }
  },
})
