







import { defineComponent } from '@nuxtjs/composition-api'
import JsonRteField from '~/components/global/cms/JsonRteField.vue'
import ContentImage from '@/components/feature/static-content/components/ContentImage.vue'

export default defineComponent({
  components: { JsonRteField, ContentImage },
  props: {
    figure: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
})
