











import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import JsonRteField from '~/components/global/cms/JsonRteField.vue'
import ContentImage from '~/components/feature/static-content/components/ContentImage.vue'

import { GfContentSlotComponentsComponentsHero } from '~/modules/contentstack/types'
import { transparency, heroColors } from '@/common/mapping/CMSMapping'

type Percentage = '0' | '25' | '50' | '75' | '100'
type Colors = 'black' | 'midnight-blue'

export default defineComponent({
  components: {
    JsonRteField,
    ContentImage,
  },
  props: {
    data: {
      required: true,
      type: Object as PropType<GfContentSlotComponentsComponentsHero>,
    },
  },
  setup(props) {
    const background = props.data.hero?.background_imageConnection?.edges?.at(0)?.node!
    const displayHero: any = computed(() => {
      return background.cloudflare_id || background.media_server_id !== ''
    })

    const heroData: any = computed(() => {
      const isCfImg: Boolean = !!background?.cloudflare_id
      const overlay: any = props.data.hero?.overlay

      const overlayType: String = overlay?.type || 'none'
      const overlayColorValue: String = props.data.hero?.overlay?.color || 'black'
      const transparencyValue: String = overlay?.transparency || 'tp_0'
      const opacity: Percentage = transparency[transparencyValue as keyof typeof transparency] as Percentage
      const color: Colors = heroColors[overlayColorValue as keyof typeof heroColors] as Colors

      let overlayClass = 'overlay tw-h-full' // default class

      switch (overlayType) {
        case 'strip':
          overlayClass = `overlay tw-h-auto tw-self-center tw-py-5 tw-bg-${color} tw-bg-opacity-${opacity}`
          break
        case 'shadow': {
          overlayClass = `overlay tw-h-full tw-bg-gradient-to-b tw-from-${color}/${opacity} tw-to-${color}/0` // ${opacityClass}`
          break
        }
        case 'dark':
          overlayClass = `overlay tw-h-full tw-bg-${color} tw-bg-opacity-${opacity}`
          break
      }

      const heroType = props.data.hero?.settings?.type || 'default'

      return {
        backgroundProvider: isCfImg ? 'mc' : 'ms',
        backgroundImage: isCfImg ? (background?.cloudflare_id ?? null) : (background?.media_server_id ?? null),
        jsonRteRootNode: props.data.hero?.content?.json,
        overlayClass,
        heroType,
      }
    })

    return { displayHero, heroData, background }
  },
})
