






















import { defineComponent, ref } from '@nuxtjs/composition-api'

import JsonRteField from '~/components/global/cms/JsonRteField.vue'

import { MappedAccordion } from '~/types/static-content/staticContentTypes'
export default defineComponent({
  components: {
    JsonRteField,
  },
  props: {
    mappedAccordionItems: {
      required: true,
      type: Array as () => MappedAccordion[],
    },
  },
  setup() {
    const openedContent = ref<number[]>([])

    const isIndexOpened = (index: number) => {
      return openedContent.value.includes(index)
    }

    const toggleContent = (index: number) => {
      openedContent.value.includes(index)
        ? openedContent.value.splice(openedContent.value.indexOf(index), 1)
        : openedContent.value.push(index)
    }

    return { isIndexOpened, toggleContent, openedContent }
  },
})
