




import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'CustomComponent',
  components: {
    ProductFinder: () => import('@/components/feature/static-content/custom-components/ProductFinder.vue'),
    ProductFinderInTable: () =>
      import('@/components/feature/static-content/custom-components/ProductFinderInTable.vue'),
    ProductsServicesSelect: () =>
      import('@/components/feature/static-content/custom-components/ProductsServicesSelect.vue'),
    PTMScanPathwaySelector: () =>
      import('@/components/feature/static-content/custom-components/PTMScanPathwaySelector.vue'),
    TermsConditionsSelect: () =>
      import('@/components/feature/static-content/custom-components/TermsConditionsSelect.vue'),
    TableSelectorOncogenesAndSuppressor: () =>
      import('@/components/feature/static-content/custom-components/TableSelector-Oncogenes-and-Suppressor.vue'),
    TableSelectorChipTroubleshootingGuide: () =>
      import('@/components/feature/static-content/custom-components/TableSelector-Chip-Troubleshooting-Guide.vue'),
    SupportFormsSelect: () => import('@/components/feature/static-content/custom-components/SupportFormsSelect.vue'),
    Promotions: () => import('@/components/feature/static-content/custom-components/Promotions.vue'),
    PrivacyPolicySelect: () => import('@/components/feature/static-content/custom-components/PrivacyPolicySelect.vue'),
    ContactUs: () => import('@/components/feature/static-content/custom-components/contact-us/ContactUs.vue'),
    SpectraViewer: () => import('@/components/feature/static-content/custom-components/SpectraViewer.vue'),
    FlowCytometryPanelBuilder: () =>
      import('@/components/feature/static-content/custom-components/FlowCytometryPanelBuilder.vue'),
    LocalInformationDistributor: () =>
      import('@/components/feature/static-content/custom-components/LocalInformationDistributor.vue'),
  },
  props: {
    data: {
      required: true,
      type: Object as () => { custom_component: { component: string } },
    },
  },
})
