























import { defineComponent } from '@nuxtjs/composition-api'
import Fa6SolidCaretDown from '~icons/fa6-solid/caret-down'
import Fa6SolidCaretUp from '~icons/fa6-solid/caret-up'

export default defineComponent({
  components: { Fa6SolidCaretDown, Fa6SolidCaretUp },
  props: {
    title: {
      required: true,
      type: String,
    },
    arrowDirection: {
      required: true,
      type: String as () => 'up' | 'down' | 'none',
    },
    customClass: {
      default: '',
      type: String,
      required: false,
    },
    href: {
      type: String,
      required: false,
      default: '',
    },
  },
})
