import { render, staticRenderFns } from "./Tiles.vue?vue&type=template&id=54ed7fe0&scoped=true&"
import script from "./Tiles.vue?vue&type=script&lang=ts&"
export * from "./Tiles.vue?vue&type=script&lang=ts&"
import style0 from "./Tiles.vue?vue&type=style&index=0&id=54ed7fe0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54ed7fe0",
  null
  
)

export default component.exports