





















import { defineComponent, useContext, PropType } from '@nuxtjs/composition-api'
import JsonRteField from '~/components/global/cms/JsonRteField.vue'
import NavigationTile from '~/components/feature/static-content/NavigationTile.vue'
import ContentImage from '~/components/feature/static-content/components/ContentImage.vue'
import { GfContentSlotComponentsComponentsTiles } from '~/modules/contentstack/types'
import { UrlConnectionItem } from '~/types/rte/rteTypes'

export default defineComponent({
  components: {
    JsonRteField,
    NavigationTile,
    ContentImage,
  },
  props: {
    data: {
      required: true,
      type: Object as PropType<GfContentSlotComponentsComponentsTiles>,
    },
  },
  setup(props) {
    const ctx = useContext()
    const columnWidth = +(props.data.tiles?.columns?.match(/\d/gm)?.at(0) ?? '0')
    const cardType = props.data.tiles?.type

    const cards = props.data.tiles?.cards?.map((card) => {
      return {
        ...card,
        imgData: card?.mediaConnection?.edges?.at(0)?.node,
        jsonRteRootNode: card?.content?.json,
        jsonEmbeddedItems: card?.content?.embedded_itemsConnection?.edges,
        urlConnectionItem: card?.urlConnection?.edges?.at(0)?.node as UrlConnectionItem,
      }
    })

    // Card styles
    const cardStyle = {
      ...(cardType === 'message' && {
        background: `url(${ctx.$img('/www/images/content-fragments/gu/nt2/banner-sm-standard-blue.png', null, {
          provider: 'ms',
        })}) repeat-x top left`,
      }),
    }

    return { cards, cardType, columnWidth, cardStyle }
  },
})
