













import { defineComponent, ref, PropType } from '@nuxtjs/composition-api'

import ContentAccordion from '~/components/feature/static-content/ContentAccordion.vue'
import ContextAccordion from '~/components/feature/static-content/ContextAccordion.vue'

import {
  GfContentSlotComponentsComponentsAccordion,
  GfContentSlotComponentsComponentsAccordionBlockItems,
} from '~/modules/contentstack/types'

export default defineComponent({
  components: {
    ContentAccordion,
    ContextAccordion,
  },
  props: {
    data: {
      required: true,
      type: Object as PropType<GfContentSlotComponentsComponentsAccordion>,
    },
    componentIndex: {
      required: false,
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const typeOfAccordion = props.data.accordion?.type ?? null
    const accordionTitle = props.data.accordion?.title ?? null
    const accordionItems = (props.data.accordion?.items ?? []) as GfContentSlotComponentsComponentsAccordionBlockItems[]

    const mapAccordionItems = (items: GfContentSlotComponentsComponentsAccordionBlockItems[]) => {
      if (typeOfAccordion === 'context') {
        return items.map((item) => ({
          title: item?.title ?? null,
          href: item?.urlConnection?.edges?.at(0)?.node?.link_v2?.href ?? null,
          content: item?.content?.json,
          embeddedItems: item?.content?.embedded_itemsConnection?.edges,
          description: item?.description ?? null,
        }))
      } else {
        return items.map((item) => ({
          title: item?.title ?? null,
          href: item?.urlConnection?.edges?.at(0)?.node?.link_v2?.href ?? null,
          content: item?.content?.json,
          embeddedItems: item?.content?.embedded_itemsConnection?.edges,
        }))
      }
    }

    const mappedAccordionItems = mapAccordionItems(accordionItems)
    const isOpenAll = ref(false)

    const onToggleAll = () => {
      isOpenAll.value = !isOpenAll.value
    }

    return { typeOfAccordion, accordionTitle, mappedAccordionItems, onToggleAll, isOpenAll }
  },
})
