























import { defineComponent, PropType } from '@nuxtjs/composition-api'
import isEmpty from 'lodash/fp/isEmpty'

// Component
import JsonRteField from '~/components/global/cms/JsonRteField.vue'

// Types
import { GfContentSlotComponentsComponentsMenu } from '~/modules/contentstack/types'

// Common
import {
  parseUrlConnectionItem,
  parseNavigationLinkConnectionItem,
} from '@/modules/contentstack/parser/contentPageParser'

export default defineComponent({
  components: {
    JsonRteField,
  },
  props: {
    data: {
      required: true,
      type: Object as PropType<GfContentSlotComponentsComponentsMenu>,
    },
  },
  setup(props) {
    const menu = props.data?.menu
    const type = menu?.type
    const title = menu?.title || null
    const parsedMenuItems = menu?.items
      ? menu?.items?.map((item) => {
          if (!item) {
            return null
          }

          const parsedUrlConnection = parseUrlConnectionItem(item.urlConnection)
          if (parsedUrlConnection) {
            parsedUrlConnection!.title = !isEmpty(item.title) ? item.title : parsedUrlConnection!.title
          }

          return {
            ...parsedUrlConnection,
            ...(item.content && { content: item.content }),
            ...(item.icon?.class_name?.length && { icon: item.icon.class_name }),
          }
        })
      : null

    const parsedMenuReferenceItems = menu?.items_referenceConnection?.edges![0]?.node?.items?.length
      ? menu.items_referenceConnection.edges[0].node.items.map((item) => {
          if (!item) {
            return null
          }

          const parseNavigationLinkConnection = parseNavigationLinkConnectionItem(item.navigation_linkConnection)
          parseNavigationLinkConnection!.title = !isEmpty(item.title)
            ? item.title
            : parseNavigationLinkConnection!.title
          return {
            ...parseNavigationLinkConnection,
            ...{ content: undefined }, // Navigation content type does not have items with content field
            ...{ icon: undefined }, // // Navigation content type does not have items with icon field
          }
        })
      : []

    const links = parsedMenuItems?.length ? parsedMenuItems : parsedMenuReferenceItems
    return { type, title, links }
  },
})
