import { CONTENT_COMPONENTS } from '~/common/constants/staticContent'
import { ContentLayoutColumns } from '~/types/static-content/staticContentTypes'

import type { ContentPage, GfContentSlotComponentsComponents } from '~/modules/contentstack/types'

export const createLayout = (data?: ContentPage) => {
  if (!data) {
    return null
  }
  let isCorrectTemplateSlot = true
  let fullWidth = 0
  const contentTemplateLayout: ContentLayoutColumns = []
  const templateDevkey = data?.templateConnection?.edges?.at(0)?.node?.devkey

  // get columns from template devkey (e.g. template_cx31)
  const contentTemplateColumns = templateDevkey?.match(/\d/gm)?.map((value) => value)
  if (!contentTemplateColumns) {
    console.error('Content Template devkey is invalid.')
    return null
  }

  contentTemplateColumns.forEach((col) => (fullWidth += +col))

  // get slot number from template slot devkey (e.g. template_cx31_s1) and push object to the position
  // of the slot with corresponding width and its components
  data?.content?.forEach((content) => {
    const templateSlotDevkey = content?.template_slotConnection?.edges?.at(0)?.node?.devkey

    if (templateDevkey && !templateSlotDevkey?.includes(templateDevkey)) {
      console.error(
        'Content Template Slot is invalid. Please use correct Content Template Slot that is associated with Content Template.'
      )
      isCorrectTemplateSlot = false
      return
    }

    const contentTemplateSlot = templateSlotDevkey?.match(/\d+(?![a-z])/gm)?.at(1)

    if (!contentTemplateSlot) {
      console.error('Content Template Slot devkey is invalid.')
      isCorrectTemplateSlot = false
      return
    }

    contentTemplateLayout[+contentTemplateSlot - 1] = {
      width: fullWidth === 1 ? 'full' : `${contentTemplateColumns[+contentTemplateSlot - 1]}/${fullWidth}`,
      components: getStaticContentTemplateLayout(content?.components as GfContentSlotComponentsComponents[]),
    }
  })

  if (!isCorrectTemplateSlot) {
    return null
  }
  return contentTemplateLayout
}

export const getStaticContentTemplateLayout = (data?: GfContentSlotComponentsComponents[]) => {
  const componentTypeIndex = {
    [CONTENT_COMPONENTS.RICH_TEXT]: 0,
    [CONTENT_COMPONENTS.IMAGE_MAP]: 0,
    [CONTENT_COMPONENTS.TILES]: 0,
    [CONTENT_COMPONENTS.HERO]: 0,
    [CONTENT_COMPONENTS.MENU]: 0,
    [CONTENT_COMPONENTS.REFERENCE]: 0,
    [CONTENT_COMPONENTS.CAROUSEL]: 0,
    [CONTENT_COMPONENTS.ACCORDION]: 0,
    [CONTENT_COMPONENTS.CUSTOM_COMPONENT]: 0,
  }
  // Expand this to return every component
  return (
    data?.map((component) => {
      let componentName = ''
      switch (component?.__typename) {
        case 'GfContentSlotComponentsComponentsRichtext':
          componentName = CONTENT_COMPONENTS.RICH_TEXT
          break
        case 'GfContentSlotComponentsComponentsImageMap':
          componentName = CONTENT_COMPONENTS.IMAGE_MAP
          break
        case 'GfContentSlotComponentsComponentsTiles':
          componentName = CONTENT_COMPONENTS.TILES
          break
        case 'GfContentSlotComponentsComponentsHero':
          componentName = CONTENT_COMPONENTS.HERO
          break
        case 'GfContentSlotComponentsComponentsMenu':
          componentName = CONTENT_COMPONENTS.MENU
          break
        case 'GfContentSlotComponentsComponentsReference':
          componentName = CONTENT_COMPONENTS.REFERENCE
          break
        case 'GfContentSlotComponentsComponentsCarousel':
          componentName = CONTENT_COMPONENTS.CAROUSEL
          break
        case 'GfContentSlotComponentsComponentsAccordion':
          componentName = CONTENT_COMPONENTS.ACCORDION
          break
        case 'GfContentSlotComponentsComponentsCustomComponent':
          componentName = CONTENT_COMPONENTS.CUSTOM_COMPONENT
          break
        default:
          componentName = CONTENT_COMPONENTS.RICH_TEXT
          break
      }

      componentTypeIndex[componentName]++

      return {
        name: componentName,
        props: { data: component, componentIndex: componentTypeIndex[componentName] },
      }
    }) ?? []
  )
}
