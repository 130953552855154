






















































import { defineComponent, ref, computed, PropType } from '@nuxtjs/composition-api'

// Component
import FigureSlide from './FigureSlide.vue'
import IconFaSolidAngleLeft from '~icons/fa-solid/angle-left'
import IconFaSolidAngleRight from '~icons/fa-solid/angle-right'

// Content page parsers
import { parseUrlConnectionItem, parseImageConnectionItem } from '@/modules/contentstack/parser/contentPageParser'

// Type
import { GfContentSlotComponentsComponentsCarousel } from '~/modules/contentstack/types'

export default defineComponent({
  components: {
    IconFaSolidAngleLeft,
    IconFaSolidAngleRight,
    FigureSlide,
  },
  props: {
    data: {
      required: true,
      type: Object as PropType<GfContentSlotComponentsComponentsCarousel>,
    },
  },
  setup(props) {
    // data
    const carouselData = props.data?.carousel
    const carouselSettings = carouselData?.settings
    const parsedCarouselItems = carouselData?.items?.map((item) => {
      return {
        content: item?.content?.json,
        image: parseImageConnectionItem(item?.imageConnection),
        url: parseUrlConnectionItem(item?.urlConnection),
      }
    })
    const carouselItemsCount = parsedCarouselItems?.length || 0

    // carousel
    const currentSlide = ref(0)
    const carousel: any = ref(null)
    const slickOptions = {
      dots: false,
      arrows: false,
      draggable: true,
      swipe: true,
      adaptiveHeight: false,
      lazyLoad: 'ondemand',
      infinite: carouselSettings?.infinite || false,
      slidesToShow: carouselSettings?.items_to_show || 1,
      slidesToScroll: carouselSettings?.items_to_scroll || 1,
      speed: carouselSettings?.speed_scroll || 500,
      responsive: [
        {
          breakpoint: 399,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: carouselSettings?.items_to_show && carouselSettings?.items_to_show === 1 ? 1 : 2,
          },
        },
      ],
    }

    const showNext = () => {
      if (carousel.value === null || carousel.value === undefined) return
      carousel.value.next()
    }

    const showPrev = () => {
      if (carousel.value === null || carousel.value === undefined) return
      carousel.value.prev()
    }

    const goTo = (index: number) => {
      if (carousel.value === null || carousel.value === undefined) return
      carousel.value.goTo(index)
    }

    const changeCurrentslide = (index: number) => {
      currentSlide.value = index
    }

    const showPrevArrow = computed(() => {
      // Infinite, always show the arrow
      if (carouselSettings?.infinite) return true

      return carouselItemsCount > 1 && currentSlide.value !== 0
    })

    const showNextArrow = computed(() => {
      // Infinite, always show the arrow
      if (carouselSettings?.infinite) return true

      return carouselItemsCount > 1 && currentSlide.value + 1 !== carouselItemsCount
    })

    return {
      carouselSettings,
      parsedCarouselItems,
      slickOptions,
      showNext,
      showPrev,
      goTo,
      carousel,
      changeCurrentslide,
      currentSlide,
      carouselItemsCount,
      showPrevArrow,
      showNextArrow,
    }
  },
})
