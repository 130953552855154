









import { defineComponent, PropType } from '@nuxtjs/composition-api'

// Component
import ContentImage from '@/components/feature/static-content/components/ContentImage.vue'
import ContentVideo from '@/components/feature/static-content/components/ContentVideo.vue'
import Form from '@/components/feature/static-content/components/Form.vue'
import Table from '~/components/feature/static-content/components/HTML-Table.vue'

// Types
import {
  GfContentSlotComponentsComponentsReferenceBlockEntityConnection,
  GfContentSlotComponentsComponentsReference,
} from '~/modules/contentstack/types'

// Common
import { parseReferenceItem } from '@/modules/contentstack/parser/contentPageParser'

export default defineComponent({
  components: {
    ContentImage,
    ContentVideo,
    Form,
    Table,
  },
  props: {
    data: {
      required: true,
      type: Object as PropType<GfContentSlotComponentsComponentsReference>,
    },
  },
  setup(props) {
    const reference = props.data?.reference
    const referenceItem = reference?.entityConnection as GfContentSlotComponentsComponentsReferenceBlockEntityConnection
    const parsedReferenceItem = parseReferenceItem(referenceItem)

    if (parsedReferenceItem?.__typename === 'ContentVideo') {
      parsedReferenceItem.isPopover = false
    }

    return { parsedReferenceItem, reference }
  },
})
