
















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import type { GfContentSlotComponentsComponentsImageMap } from '~/modules/contentstack/types'

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object as PropType<GfContentSlotComponentsComponentsImageMap>,
    },
  },
  setup(props) {
    const altText = props.data.image_map?.imageConnection?.edges?.at(0)?.node?.alt_text
    const imgSrc = props.data.image_map?.imageConnection?.edges?.at(0)?.node?.media_server_id
    const mapName = props.data.image_map?.map_name ?? ''
    const mapTitle = props.data.image_map?.map_title ?? ''
    const areas = props.data.image_map?.areas?.map((area) => ({
      ...area,
      link: area?.navigation_linkConnection?.edges?.at(0)?.node?.link_v2?.href,
    }))
    return { altText, imgSrc, mapName, mapTitle, areas }
  },
})
