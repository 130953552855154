import { useContext } from '@nuxtjs/composition-api'

// Composables
import useAnalytics from '@/composables/analytics/useAnalytics'

export default () => {
  const context = useContext()
  const analytics = useAnalytics()

  /*
    Track submitted forms
  */
  const trackFormSubmit = (formName: string, formId: string) => {
    // GA4
    const options = {
      event: 'form_success',
      form_name: formName,
      form_id: formId,
      ...analytics.getCustomDimensions().ga4,
    }
    context.$gtm.push(options)
  }

  return {
    trackFormSubmit,
  }
}
