































import { defineComponent, ref, onMounted, useContext, useRoute } from '@nuxtjs/composition-api'

// Types
import { IframeHTMLAttributes } from '@vue/runtime-dom'
import { Form } from '~/modules/contentstack/types'

// Composables
import useContentAnalytics from '~/composables/analytics/useContentAnalytics'
import { useCountry } from '~/composables/country/useCountry'

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object as () => Form,
    },
    height: {
      type: Number,
      default: 500,
    },
  },
  emits: ['onLoad', 'onSubmit'],
  setup(props, { emit }) {
    const ctx = useContext()
    const { selectedCountry } = useCountry()
    const route = useRoute()
    const contentAnalytics = useContentAnalytics()
    const DEFAULT_IFRAME_ATTRS: IframeHTMLAttributes = {
      frameborder: '0',
      scrolling: 'no',
      allowtransparency: 'true',
      width: '100%',
      height: props.height,
    }
    const iframeLoaded = ref(false)
    const scriptLoaded = ref(false)
    const formIframe = ref<HTMLIFrameElement | null>(null)
    const isHubSpot = props.data.type === 'hsform'
    const isFormAssembly = props.data.type === 'tfaform'
    const isGoldenData = props.data.type === 'gdform'
    const iFrameId =
      isFormAssembly && props.data?.form_id ? props.data.form_id : `goldendata_form_${props.data.form_id}`
    const hubspotForm = ref<any>(null)

    // Method to render a Hubspot form to the page
    const renderHubsportForm = () => {
      // Add the script, listen to the load event and create the form
      const script = document.createElement('script')
      script.src = 'https://js.hsforms.net/forms/v2.js'
      document.body.appendChild(script)
      script.addEventListener('load', () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: '214398',
            formId: props.data.form_id,
            target: `#${hubspotForm.value.id}`,
            onFormSubmit: () => {
              trackSubmit()
            },
            onBeforeFormInit: () => {
              iframeLoaded.value = true
            },
          })
        }
      })
    }

    const trackSubmit = () => {
      const formName = props.data.title || `${props.data.type} form on ${ctx.route.value.fullPath}`
      const formId = props.data.form_id?.toString() || ''
      contentAnalytics.trackFormSubmit(formName, formId)
      emit('onSubmit')
    }

    // .. iFrame communicates via postMessage functions
    onMounted(() => {
      if (process.client) {
        if (isHubSpot) {
          renderHubsportForm()
        } else {
          window.addEventListener('message', handleIFrameMsg, false)
        }
      }
    })

    // Try to parse JSON
    const tryJsonParse = (str: string) => {
      try {
        return JSON.parse(str)
      } catch (e) {
        return false
      }
    }

    // .. iFrame main handler of communication
    const handleIFrameMsg = (event: MessageEvent) => {
      // console.log('event', event)
      if (event.data) {
        // input can be a stringified JSON or a plain object
        const data = typeof event.data === 'string' ? tryJsonParse(event.data) : event.data
        if (!data) return

        // Listen to child message, respond that the parent is loaded
        if (data.event === 'iamListening') {
          formIframe?.value?.contentWindow?.postMessage(
            JSON.stringify({ event: 'iamLoaded', id: props.data.form_id }),
            '*'
          )
        }

        // Listen to form load event to set the height of the iframe
        if (data.event === 'heightChanged') {
          // form assembly height changes are handled in tfaform-helper-script
          if (isGoldenData) {
            formIframe?.value?.style?.setProperty('height', `${data.value}px`)
          }
        }

        // .. Handle message for submit
        if (data.event === 'formSubmit') {
          console.log('data', data)
          trackSubmit()
        }
      }
    }

    // Handle load event
    const handleLoad = () => {
      iframeLoaded.value = true
      scriptLoaded.value = !isFormAssembly || !!formIframe.value?.src.includes('hostURL=')

      emit('onLoad')
    }

    const getFormParams = () => {
      const params = props.data?.form_params?.map((param) => {
        if (param?.param_name === 'country' && !param?.param_value) {
          return `${param.param_name}=${selectedCountry.value === 'US' ? 'USA' : selectedCountry.value}`
        }
        if (
          ['product_of_interest', 'product_name', 'product_number'].includes(param?.param_name as string) &&
          !param?.param_value
        ) {
          return `${param?.param_name}=${route.value.query[param?.param_name as string]}`
        }
        return `${param?.param_name}=${param?.param_value}`
      })

      return params?.join('&')
    }

    const getFormSettings = () => {
      const params = getFormParams()

      if (isFormAssembly) {
        return {
          url: new URL(
            `${ctx.$config.externalForms.formAssemblyUrl}/${props.data.form_id}${params ? '?' + params : ''}`
          ).toString(),
          script: '/nuxt/form-assembly/tfaform-helper-script.js',
        }
      }

      if (isGoldenData) {
        return {
          url: new URL(
            `${ctx.$config.externalForms.goldenDataUrl}/${
              props.data.form_id
            }?background=white&banner=hide&embedded=true&inner_redirect=false${params ? '&' + params : ''}`
          ).toString(),
          script: '',
        }
      }

      return {
        url: '',
        script: '',
      }
    }
    const formSettings = props?.data?.type ? getFormSettings() : { url: '', script: '' }

    return {
      DEFAULT_IFRAME_ATTRS,
      iframeLoaded,
      scriptLoaded,
      handleLoad,
      formSettings,
      formIframe,
      iFrameId,
      hubspotForm,
      isHubSpot,
    }
  },
  head: {},
})
