












import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { GfContentSlotComponentsComponentsTilesBlockCards } from '~/modules/contentstack/types'

export default defineComponent({
  props: {
    card: {
      required: true,
      type: Object as PropType<GfContentSlotComponentsComponentsTilesBlockCards>,
    },
  },
  setup(props) {
    const url = props.card.urlConnection?.edges?.at(0)?.node
    const linkHref = url?.link_v2?.href ?? ''
    const linkTitle = url?.link_v2?.title ?? ''

    const img = props.card.mediaConnection?.edges?.at(0)?.node
    const isCfImg = !!img?.cloudflare_id
    const imgAttrs = {
      provider: isCfImg ? 'mc' : 'ms',
      alt: img?.alt_text ?? null,
      url: isCfImg ? (img?.cloudflare_id ?? null) : (img?.media_server_id ?? null),
    }
    return { linkHref, linkTitle, imgAttrs, isCfImg }
  },
})
