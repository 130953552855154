






















































import { defineComponent, ref, useRoute, watch } from '@nuxtjs/composition-api'

import JsonRteField from '~/components/global/cms/JsonRteField.vue'
import Heading from '~/components/global/accordions/heading.vue'

import { MappedContextAccordion } from '~/types/static-content/staticContentTypes'
export default defineComponent({
  components: {
    JsonRteField,
    Heading,
  },
  props: {
    mappedAccordionItems: {
      required: true,
      type: Array as () => MappedContextAccordion[],
    },
    accordionTitle: {
      required: false,
      type: String,
      default: null,
    },
    isOpenAll: {
      required: true,
      type: Boolean,
    },
    componentIndex: {
      required: false,
      type: Number,
      default: 1,
    },
  },
  emits: ['toggle-all'],
  setup(props) {
    const route = useRoute()

    const openedContent = ref<number[]>([])

    const parseCollapseId = () => {
      const hash = route.value.hash
      const index = hash.replace(`#collapse${props.componentIndex - 1}`, '')
      return Number.parseInt(index)
    }

    if (route.value.hash) {
      openedContent.value.push(parseCollapseId())
    }

    const isIndexOpened = (index: number) => {
      return openedContent.value.includes(index)
    }

    const toggleContent = (index: number) => {
      if (openedContent.value.includes(index)) {
        // route.value.hash && router.replace({ hash: undefined })
        openedContent.value.splice(openedContent.value.indexOf(index), 1)
      } else {
        openedContent.value.push(index)
        // const routeHash = generateCollapseHash(index)
        // route.value.hash !== routeHash && router.replace({ hash: routeHash })
      }
    }

    const generateCollapseHash = (index: number) => {
      return `#${generateCollapseId(index)}`
    }

    const generateCollapseId = (index: number) => {
      const id = 'collapse' + (props.componentIndex - 1) + index
      return id
    }

    watch(
      () => props.isOpenAll,
      () => {
        openedContent.value.splice(0, openedContent.value.length)
        if (props.isOpenAll) {
          openedContent.value.push(...props.mappedAccordionItems.keys())
        }
      }
    )

    return { openedContent, toggleContent, isIndexOpened, generateCollapseId, generateCollapseHash }
  },
})
