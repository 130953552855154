












import { defineComponent, PropType } from '@nuxtjs/composition-api'
import JsonRteField from '~/components/global/cms/JsonRteField.vue'
import { GfContentSlotComponentsComponentsRichtext } from '~/modules/contentstack/types'
import { isEmptyJsonRteField } from '~/common/utils/static-content/cms'

export default defineComponent({
  components: {
    JsonRteField,
  },
  props: {
    data: {
      required: true,
      type: Object as PropType<GfContentSlotComponentsComponentsRichtext>,
    },
  },
  setup(props) {
    const jsonRichTextHtml = props.data.richtext?.json_rich_text?.json
    const embeddedItems = props.data.richtext?.json_rich_text?.embedded_itemsConnection?.edges
    const htmlRichTextHtml = props.data.richtext?.html_rich_text

    return { jsonRichTextHtml, htmlRichTextHtml, embeddedItems, isEmptyJsonRteField }
  },
})
