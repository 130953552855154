


































import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    accordionData: {
      required: true,
      type: Object as any,
    },
  },
  setup() {
    const openedItems = ref<number[]>([])

    const toggleOne = (index: number) => {
      if (openedItems.value.includes(index)) {
        openedItems.value = openedItems.value.filter((i) => i !== index)
      } else {
        openedItems.value.push(index)
      }
    }

    return { openedItems, toggleOne }
  },
})
